var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-py-5"},[_c('h2',{staticClass:"tw-font-ave tw-text-primary tw-font-semibold tw-text-3xl"},[_vm._v(" "+_vm._s(_vm.$t('changePassword'))+" ")]),_c('div',{staticClass:"tw-mt-12 tw-max-w-md"},[_c('v-form',{ref:"formRef",staticClass:"tw-grid tw-grid-cols-1 tw-gap-3 md:tw-gap-6",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.onUpdatePassword.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{staticClass:"user-input",attrs:{"label":_vm.$t('oldPassword'),"large-label":_vm.$vuetify.breakpoint.mdAndUp,"append-icon":_vm.isVisiblePassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.isVisiblePassword ? 'text' : 'password',"rules":[_vm.rules.required(_vm.$t('oldPassword')), _vm.rules.password],"error-messages":_vm.errMessages,"flat":"","dense":"","outlined":"","name":"previousPassword","hide-details":"auto","background-color":"#FBFBFB"},on:{"click:append":function($event){_vm.isVisiblePassword = !_vm.isVisiblePassword},"input":function($event){_vm.errMessages = []}},model:{value:(_vm.user.previousPassword),callback:function ($$v) {_vm.$set(_vm.user, "previousPassword", $$v)},expression:"user.previousPassword"}}),_c('v-text-field',{staticClass:"user-input",attrs:{"label":_vm.$t('newPassword'),"append-icon":_vm.isVisibleNewPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.isVisibleNewPassword ? 'text' : 'password',"large-label":_vm.$vuetify.breakpoint.mdAndUp,"rules":[
					_vm.rules.required(_vm.$t('password')),
					_vm.rules.password,
					function (v) { return _vm.rules.cantMatch(
							v,
							_vm.user.previousPassword,
							_vm.$t('previousPassword'),
							_vm.$t('password')
						); } ],"flat":"","dense":"","outlined":"","name":"password","hide-details":"auto","background-color":"#FBFBFB"},on:{"click:append":function($event){_vm.isVisibleNewPassword = !_vm.isVisibleNewPassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('v-text-field',{staticClass:"user-input",attrs:{"label":_vm.$t('retypePassword'),"type":_vm.isVisiblePasswordConfirmation ? 'text' : 'password',"large-label":_vm.$vuetify.breakpoint.mdAndUp,"append-icon":_vm.isVisiblePasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off',"rules":[
					_vm.rules.required(_vm.$t('password')),
					function (v) { return _vm.rules.match(v, _vm.user.password, _vm.$t('password')); } ],"flat":"","dense":"","outlined":"","name":"newPasswordConfirmation","hide-details":"auto","background-color":"#FBFBFB"},on:{"click:append":function($event){_vm.isVisiblePasswordConfirmation = !_vm.isVisiblePasswordConfirmation}},model:{value:(_vm.user.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.user, "passwordConfirmation", $$v)},expression:"user.passwordConfirmation"}}),_c('div',{staticClass:"tw-mt-14 tw-flex tw-space-x-4"},[_c('v-btn',{staticClass:"tw-flex-1 tw-rounded-lg",attrs:{"text":"","outlined":"","height":"58","color":"primary"},on:{"click":_vm.onClickCancel}},[_c('span',{staticClass:"tw-text-lg tw-font-medium tw-capitalize tw-text-72"},[_vm._v(_vm._s(_vm.$t('actions.cancel')))])]),_c('v-btn',{staticClass:"tw-flex-1 tw-font-medium tw-rounded-lg",attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading || !_vm.isFormValid,"depressed":"","height":"58","type":"submit","color":"primary"}},[_c('span',{staticClass:"tw-text-lg tw-font-medium tw-capitalize tw-text-white"},[_vm._v(_vm._s(_vm.$t('actions.submit')))])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }