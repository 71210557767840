<template>
	<div class="tw-py-5">
		<h2 class="tw-font-ave tw-text-primary tw-font-semibold tw-text-3xl">
			{{ $t('changePassword') }}
		</h2>
		<div class="tw-mt-12 tw-max-w-md">
			<v-form
				lazy-validation
				ref="formRef"
				class="tw-grid tw-grid-cols-1 tw-gap-3 md:tw-gap-6"
				v-model="isFormValid"
				@submit.prevent="onUpdatePassword"
			>
				<v-text-field
					v-model="user.previousPassword"
					:label="$t('oldPassword')"
					:large-label="$vuetify.breakpoint.mdAndUp"
					:append-icon="isVisiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
					:type="isVisiblePassword ? 'text' : 'password'"
					:rules="[rules.required($t('oldPassword')), rules.password]"
					:error-messages="errMessages"
					flat
					dense
					outlined
					class="user-input"
					name="previousPassword"
					hide-details="auto"
					background-color="#FBFBFB"
					@click:append="isVisiblePassword = !isVisiblePassword"
					@input="errMessages = []"
				/>

				<v-text-field
					v-model="user.password"
					:label="$t('newPassword')"
					:append-icon="isVisibleNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
					:type="isVisibleNewPassword ? 'text' : 'password'"
					:large-label="$vuetify.breakpoint.mdAndUp"
					:rules="[
						rules.required($t('password')),
						rules.password,
						(v) =>
							rules.cantMatch(
								v,
								user.previousPassword,
								$t('previousPassword'),
								$t('password')
							),
					]"
					flat
					dense
					outlined
					class="user-input"
					name="password"
					hide-details="auto"
					background-color="#FBFBFB"
					@click:append="isVisibleNewPassword = !isVisibleNewPassword"
				/>

				<v-text-field
					v-model="user.passwordConfirmation"
					:label="$t('retypePassword')"
					:type="isVisiblePasswordConfirmation ? 'text' : 'password'"
					:large-label="$vuetify.breakpoint.mdAndUp"
					:append-icon="
						isVisiblePasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
					"
					:rules="[
						rules.required($t('password')),
						(v) => rules.match(v, user.password, $t('password')),
					]"
					flat
					dense
					outlined
					class="user-input"
					name="newPasswordConfirmation"
					hide-details="auto"
					background-color="#FBFBFB"
					@click:append="
						isVisiblePasswordConfirmation = !isVisiblePasswordConfirmation
					"
				/>

				<div class="tw-mt-14 tw-flex tw-space-x-4">
					<v-btn
						text
						outlined
						height="58"
						color="primary"
						class="tw-flex-1 tw-rounded-lg"
						@click="onClickCancel"
					>
						<span
							class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
							>{{ $t('actions.cancel') }}</span
						>
					</v-btn>
					<v-btn
						:loading="isLoading"
						:disabled="isLoading || !isFormValid"
						depressed
						height="58"
						type="submit"
						color="primary"
						class="tw-flex-1 tw-font-medium tw-rounded-lg"
					>
						<span
							class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
							>{{ $t('actions.submit') }}</span
						>
					</v-btn>
				</div>
			</v-form>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { createFormMixin } from '../mixins/form-mixin'
export default {
	name: 'ChangePassword',
	mixins: [
		createFormMixin({
			rules: ['required', 'password', 'match', 'cantMatch'],
		}),
	],
	data: () => ({
		isLoading: false,
		isFormValid: false,
		isVisiblePassword: false,
		isVisibleNewPassword: false,
		isVisiblePasswordConfirmation: false,
		errMessages: [],
		user: {
			previousPassword: '',
			password: '',
			passwordConfirmation: '',
		},
	}),
	created() {},
	methods: {
		...mapActions('auth', ['logout', 'updatePassword']),
		onClickCancel() {
			this.$refs.formRef.reset()
		},
		async onUpdatePassword() {
			if (!this.$refs.formRef.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			this.isLoading = true
			let [err] = await this.updatePassword({
				...this.user,
				email: this.$store.state.auth.currentUser.email,
			})
			this.isLoading = false

			if (err) {
            this.$utils.scrollIntoError(this)
				this.errMessages.push(err.message)
				return this.$toast.error(
					this.$t('error-updating', [this.$t('password')])
				)
			}

			this.$toast.success(this.$t('success-update', [this.$t('password')]))
			await this.logout()
			this.$router.push('/login')
		},
	},
}
</script>
